document.addEventListener('DOMContentLoaded', function() {

  var amountInputElement = document.getElementById('payment_amount');
  var wordsInputElement = document.getElementById('words');

  if (amountInputElement && wordsInputElement) {
      var amount = amountInputElement.value;
      // Adăugați " Lei" după valoarea din input-ul cu ID-ul "payment_amount"
      amountInputElement.value += " Lei";
      var words = float_to_words(amount);
      wordsInputElement.value = words;
  }
});


function float_to_words(number) {
  // Îndepărtați comentariul de la linia de mai jos
  // number = document.getElementById(id).value;

  // Împărțim numărul în parte întreagă și parte zecimală
  var parts = number.split(',');
  var integerPart = parts[0];
  var decimalPart = parts[1] || '';

  var rezultat = '';

  if (integerPart) {
    rezultat = integer_to_words(integerPart) + " lei";
  }

  // Verificăm dacă există parte zecimală și o convertim în litere
  if (decimalPart) {
    var decimalWords = integer_to_words(decimalPart);
    // Adăugăm "și" doar dacă există și parte întreagă și parte zecimală
    rezultat += (integerPart ? " și " : "");
    rezultat += decimalWords.trim() === "zero" ? "zero bani" : decimalWords + " de bani";
  }

  return rezultat;
}

function integer_to_words(number) {
  var junkVal = Math.floor(number);
  var obStr = new String(junkVal);
  var numReversed = obStr.split("");
  var actnumber = numReversed.reverse();

  if (Number(junkVal) >= 0) {
    //do nothing
  }
  else {
    //alert('wrong Number cannot be converted');
    return '';
  }
  if (Number(junkVal) == 0) {
    return 'zero';
  }
  if (actnumber.length > 9) {
    //alert('Oops!!!! the Number is too big to covertes');
    return '';
  }

  var iWords = ["zero", " unu", " doi", " trei", " patru", " cinci", " sase", " sapte", " opt", " noua"];
  var ePlace = ['zece', ' unsprezece', ' doisprezece', ' treisprezece', ' paisprezece', ' cincisprezece', ' saisprezece', ' saptesprezece', ' optsprezece', ' nouasprezece'];
  var tensPlace = ['dummy', ' zece', ' douazeci', ' treizeci', ' patruzeci', ' cincizeci', ' saizeci', ' saptezeci', ' optzeci', ' nouazeci'];

  var iWordsLength = numReversed.length;
  var inWords = [];
  var finalWord = "";
  var j = 0;
  for (var i = 0; i < iWordsLength; i++) {
    switch (i) {
      case 0:
        if (actnumber[i] == 0 || actnumber[i + 1] == 1) {
          inWords[j] = '';
        }
        else {
          inWords[j] = iWords[actnumber[i]];
        }
        break;
      case 1:
        tens_complication();
        break;
      case 2:
        if (actnumber[i] == 0) {
          inWords[j] = '';
        }
        else if (actnumber[i - 1] != 0 && actnumber[i - 2] != 0) {
          sute = iWords[actnumber[i]] == " unu" ? " o suta" : (actnumber[i] == 2 ? "doua sute" : iWords[actnumber[i]] + " sute");
          inWords[j] = sute;
        }
        else {
          sute = iWords[actnumber[i]] == " unu" ? " o suta" : (actnumber[i] == 2 ? "doua sute" : iWords[actnumber[i]] + " sute");
          inWords[j] = sute;
        }
        break;
      case 3:
        if (actnumber[i] == 0 || actnumber[i + 1] == 1) {
          inWords[j] = '';
        }
        else {
          inWords[j] = iWords[actnumber[i]];
        }
        if (actnumber[i + 1] != 0 || actnumber[i] > 0) {
          mie = iWords[actnumber[i]] == " unu" ? " o mie " : (actnumber[i] == 2 ? "doua mii " : iWords[actnumber[i]] + " mii ");
          inWords[j] = mie;
        }
        break;
      case 4:
        tens_complication();
        break;
      case 5:
        if (actnumber[i] == 0 || actnumber[i + 1] == 1) {
          inWords[j] = '';
        }
        else {
          inWords[j] = iWords[actnumber[i]];
        }
        break;
      case 6:
        tens_complication();
        break;
      case 7:
        if (actnumber[i] == 0 || actnumber[i + 1] == 1) {
          inWords[j] = '';
        }
        else {
          inWords[j] = iWords[actnumber[i]];
        }
        break;
      case 8:
        tens_complication();
        break;
      default:
        break;
    }
    j++;
  }

  function tens_complication() {
    if (actnumber[i] == 0) {
      inWords[j] = '';
    }
    else if (actnumber[i] == 1) {
      inWords[j] = ePlace[actnumber[i - 1]];
    }
    else {
      inWords[j] = tensPlace[actnumber[i]];
      if (actnumber[i - 1] != "0") {
        inWords[j] += " si";
      }
    }
  }
  inWords.reverse();
  for (var k = 0; k < inWords.length; k++) {
    finalWord += inWords[k];
  }
  return finalWord.trim();
}
