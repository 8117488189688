// if (navigator.serviceWorker) {
// 	navigator.serviceWorker
// 		.register('/service-worker.js', { scope: './' })
// 		.then(function (reg) {
// 			console.log('[Companion]', 'Service worker registered!');
// 			console.log(reg);
// 			console.log('update pwa!');
// 		});

// }

if (navigator.serviceWorker) {
    navigator.serviceWorker
    .register("/service_worker.js", { scope: "./" })
      .then(() => navigator.serviceWorker.ready)
      .then((registration) => {
        if ("SyncManager" in window) {
          registration.sync.register("sync-forms");
        } else {
          // window.alert("This browser does not support background sync.")
          console.log('update pwa!');
// 		});
        }
      }).then(() => console.log("[Companion]", "Service worker updated!"));
  }