import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";



// gsap.registerPlugin(ScrollTrigger);






// <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.8.0/gsap.min.js"></script>
// <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.8.0/ScrollTrigger.min.js"></script>

// <!-- <script src="https://cdn.jsdelivr.net/npm/locomotive-scroll@3.5.4/dist/locomotive-scroll.min.js"></script> -->

// {/* <script>




// const locoScroll = new LocomotiveScroll({ */}
//     el: document.querySelector(".scrollContainer"),
//     smooth: true
// });

// locoScroll.on("scroll", ScrollTrigger.update);

// // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
// ScrollTrigger.scrollerProxy(".scrollContainer", {
//     scrollTop(value) {
//         return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
//     }, // we don't have to define a scrollLeft because we're only scrolling vertically.
//     getBoundingClientRect() {
//         return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
//     },
//     // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
//     pinType: document.querySelector(".scrollContainer").style.transform ? "transform" : "fixed"
// });














function animateFrom(elem, direction) {
direction = direction || 1;
var x = 0,
    y = direction * 100;
if(elem.classList.contains("gs_reveal_fromLeft")) {
  x = -200;
  y = 0;
} else if (elem.classList.contains("gs_reveal_fromRight")) {
  x = 200;
  y = 0;
}
elem.style.transform = "translate(" + x + "px, " + y + "px)";
elem.style.opacity = "0";
gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
  duration: 2.50, 
  x: 0,
  y: 0, 
  autoAlpha: 1, 
  ease: "expo", 
  overwrite: "auto"
});
}

function hide(elem) {
gsap.set(elem, {autoAlpha: 0});
}

document.addEventListener("DOMContentLoaded", function() {
gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".gs_reveal").forEach(function(elem) {
  hide(elem); // assure that the element is hidden when scrolled into view
  
  ScrollTrigger.create({
    trigger: elem,
    // markers: true,
    // scroller: ".scrollContainer",
    onEnter: function() { animateFrom(elem) }, 
    onEnterBack: function() { animateFrom(elem, -1) },
    onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
  });
});
});














// each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
// ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

// // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
// ScrollTrigger.refresh();

//watch the free video on how this demo was made
// https://www.snorkl.tv/scrolltrigger-smooth-scroll/

// </script>



{/* <script>


  // gsap.registerPlugin(ScrollTrigger);
  // gsap.to(['.welcome h4'], {
  //   autoAlpha: 0,
  //   ease: "none",
  //   scrollTrigger: {
  //     trigger: '.welcome',
  //     start: 'top top+=15%',
  //     // scroller: ".scrollContainer",
  //     pin: true,
  //     scrub: true,
  //     markers: true
  //   }
  // });



</script> */}