// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
console.time('hello');

require("@rails/ujs").start();
// require("@hotwired/turbo-rails");
// import "@hotwired/turbo-rails"
require("@rails/activestorage").start();
require("channels");
import "@hotwired/turbo-rails";
Turbo.session.drive = false
import "../custom/companion";

import "../src/jquery"
import "../custom/analytics"
import "../custom/cookie";
import "materialize-css/dist/js/materialize.min";

import "../custom/float_to_words";



import "vanilla-nested";



require("openweather/openweather.min.js");
require('default-passive-events');


require("../plugins/index");

require("../perfect-scrollbar/jquery.scrollbar.min.js");

// de aici incepe

var i = 0;




require("gsap/gsap");





document.addEventListener("turbo:load", function() {


});

Turbo.setProgressBarDelay();


