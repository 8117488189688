


import "sparkline/jquery.sparkline.min.js";




document.addEventListener('turbo:before-render', () => {
	const elem = document.querySelector('#slide-out');
	const instance = M.Sidenav.getInstance(elem);
	if (instance) {
		instance.destroy();
	}

});

document.addEventListener("turbo:load", function() {
	

	// start change language

		var localeSelect = document.getElementById('locale_select');
		if (localeSelect) {
		  localeSelect.addEventListener('change', function() {
			var selectedLocale = this.value;
			var currentPath = window.location.pathname.replace(/\/$/, ''); // Elimină '/' de la sfârșit dacă există
			var newPath = currentPath.replace(/^\/[a-z]{2}/, '/' + selectedLocale);
			if (!/^\/[a-z]{2}/.test(currentPath)) {
			  newPath = '/' + selectedLocale + currentPath;
			}
			window.location.href = newPath;
		  });
		}
	 
	  

	  // end change language

	  

// scrollbar

$('.scrollbar-macosx').scrollbar({
	"duration" : 400,
	// "disableBodyScroll" : true
})


// end scrollbar


	$(function () {
		$('#id').on('change', function (event) {
			$(this).closest('form').trigger('submit');
		});
	});










	// percentage loader
	jQuery(function () {
		var count = 0;
		var counter = setInterval(function () {
			if (count < 101) {
				$(".count").html(count + "%" + "</br>" + "Societas");
				$(".loader").css("width", count + "%");
				count++;
			} else {
				clearInterval(counter);
			}
		}, 10);
	});
	// end percentage loader


	// show hide password =======================================================================

	var visibilityToggle = $(".visibility");
	var visibilityToggle2 = $(".visibility2");

	var input = $(".input-password input");
	var input2 = $(".input-password2 input");

	var password = true;

	visibilityToggle.on("click", function () {
		if (password) {
			input.attr("type", "text");
			visibilityToggle.html("visibility");
		} else {
			input.attr("type", "password");
			visibilityToggle.html("visibility_off");
		}
		password = !password;
	});

	var password2 = true;

	visibilityToggle2.on("click", function () {
		if (password2) {
			input2.attr("type", "text");
			visibilityToggle2.html("visibility");
		} else {
			input2.attr("type", "password");
			visibilityToggle2.html("visibility_off");
		}
		password2 = !password2;
	});

	// end show hide password ==========================================================================

	// start password confirm

	$("#password").on("focusout", function (e) {
		if ($(this).val() != $("#passwordConfirm").val()) {
			$("#passwordConfirm").removeClass("valid").addClass("invalid");
		} else {
			$("#passwordConfirm").removeClass("invalid").addClass("valid");
		}
	});

	$("#passwordConfirm").on("keyup", function (e) {
		if ($("#password").val() != $(this).val()) {
			$(this).removeClass("valid").addClass("invalid");
		} else {
			$(this).removeClass("invalid").addClass("valid");
		}
	});

	// end password confirm



	// preloader form =============================================
	$(function () {
		$(".auth").on("click", function () {
			$(".displayLoading").css("display", "block");
		});
	});
	// end preloader =============================================

	// buton dezactivat pana se completeaza formularul ===========================
	(function () {
		$("form input").on("input", function () {
			var empty = false;
			$("form  input").each(function () {
				if (!empty && $(this).val() == "") {
					empty = true;
				}
			});
			$(".auth").prop("disabled", empty);
		});
	})();


	// end buton dezactivat pana se completeaza formularul ===========================

	// sparkline initialize
	$("#clients-bar").sparkline([70, 80, 65, 78, 58, 80, 78, 80, 70, 50, 75, 65, 80, 70, 65, 90, 65, 80, 70, 65, 90], {
		type: "bar",
		height: "25",
		barWidth: 7,
		barSpacing: 4,
		barColor: "#b2ebf2",
		negBarColor: "#81d4fa",
		zeroColor: "#81d4fa"
	});

	// Total Sales - Bar
	$("#sales-compositebar").sparkline([4, 6, 7, 7, 4, 3, 2, 3, 1, 4, 6, 5, 9, 4, 6, 7, 7, 4, 6, 5, 9], {
		type: "bar",
		barColor: "#F6CAFD",
		height: "25",
		width: "100%",
		barWidth: "7",
		barSpacing: 4
	});
	//Total Sales - Line
	$("#sales-compositebar").sparkline([4, 1, 5, 7, 9, 9, 8, 8, 4, 2, 5, 6, 7], {
		composite: true,
		type: "line",
		width: "100%",
		lineWidth: 2,
		lineColor: "#fff3e0",
		fillColor: "rgba(255, 82, 82, 0.25)",
		highlightSpotColor: "#fff3e0",
		highlightLineColor: "#fff3e0",
		minSpotColor: "#00bcd4",
		maxSpotColor: "#00e676",
		spotColor: "#fff3e0",
		spotRadius: 4
	});
	// Tristate chart (Today Profit)
	$("#profit-tristate").sparkline([2, 3, 0, 4, -5, -6, 7, -2, 3, 0, 2, 3, -1, 0, 2, 3, 3, -1, 0, 2, 3], {
		type: "tristate",
		width: "100%",
		height: "25",
		posBarColor: "#ffecb3",
		negBarColor: "#fff8e1",
		barWidth: 7,
		barSpacing: 4,
		zeroAxis: false
	});
	// Line chart ( New Invoice)
	$("#invoice-line").sparkline([5, 6, 7, 9, 9, 5, 3, 2, 2, 4, 6, 7, 5, 6, 7, 9, 9, 5], {
		type: "line",
		width: "100%",
		height: "25",
		lineWidth: 2,
		lineColor: "#E1D0FF",
		fillColor: "rgba(255, 255, 255, 0.2)",
		highlightSpotColor: "#E1D0FF",
		highlightLineColor: "#E1D0FF",
		minSpotColor: "#00bcd4",
		maxSpotColor: "#4caf50",
		spotColor: "#E1D0FF",
		spotRadius: 4
	});
	// end sparkline


	// scroll top - bottom
	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 200) {
			$('#topBtn').fadeIn();
		} else {
			$('#topBtn').fadeOut();
		}
	});

	$("#downBtn, #lawBtn").on('click', function () {
		$('html, body').animate({
			scrollTop: $("#scrollBtn").offset().top
		}, 800);
	});
	$("#topBtn").on('click', function () {
		$('html, body').animate({
			scrollTop: 0
		}, 800);
	});
	// end scroll top - bottom

	// close alert card
	$(".card-alert .close").on('click', function () {
		$(this)
			.closest(".card-alert")
			.fadeOut("slow");
	});
	// end close alert card

	//reset form field =======================
	$(":reset");
	// end reset form field ========================

	// notice alert
	setTimeout(function () {
		$("#notice-wrapper").fadeOut(1500, function () {
			$(this).remove();
		});
	}, 4500);
	// end notice alert
	window.sidebarLeft = function () {
		// sidebar facturi tab
		// var appContentOverlay = $(".app-file-overlay"),
		// 	sideBarLeft = $(".sidebar-left")

		// $(".app-file-sidebar-close").on('click', function () {
		// 	sideBarLeft.removeClass('show');
		// 	appContentOverlay.removeClass('show');
		// });


		// $('.sidebar-toggle').on('click', function (e) {
		// 	e.stopPropagation();
		// 	sideBarLeft.addClass('show');
		// 	appContentOverlay.addClass('show');
		// });
	}
	// end sidebar facturi tab




// la contoare meniul cu apa rece
	var appContentOverlay = $(".app-file-overlay"),
    sideBarLeft = $(".sidebar-left")
  $('.sidebar-toggle').on('click', function (e) {
    e.stopPropagation();
    sideBarLeft.addClass('show');
    appContentOverlay.addClass('show');
  });
  var appContentOverlay = $(".app-file-overlay"),
    sideBarLeft = $(".sidebar-left")

  // Sidebar menu close button on click remove show class form both sidebar-left and App content overlay
  $(".app-file-sidebar-close").on('click', function () {
    sideBarLeft.removeClass('show');
    appContentOverlay.removeClass('show');
  });


// end la contoare meniul cu apa rece



	// Materialize init

	//waves effect intre pagini
	Waves.displayEffect();
	//end waves effect intre pagini

	$('.datepicker').datepicker({
		showClearBtn: true,
		autoClose: true,
		i18n: {
			clear: 'reset',
			cancel: 'Anuleaza',
			months:
				[
					'Ianuarie',
					'Februarie',
					'Martie',
					'Aprilie',
					'Mai',
					'Iunie',
					'Iulie',
					'August',
					'Septembrie',
					'Octombrie',
					'Noiembrie',
					'Decembrie'
				],
			monthsShort:
				[
					'Ian',
					'Feb',
					'Mar',
					'Apr',
					'Mai',
					'Iun',
					'Iul',
					'Aug',
					'Sep',
					'Oct',
					'Nov',
					'Dec'
				],
			weekdays:
				[
					'Duminica',
					'Luni',
					'Marti',
					'Miercuri',
					'Joi',
					'Vineri',
					'Sambata'
				],
			weekdaysShort:
				[
					'Dum',
					'Lun',
					'Mar',
					'Mie',
					'Joi',
					'Vin',
					'Sam'
				],
			weekdaysAbbrev: ['D', 'L', 'M', 'Mi', 'J', 'V', 'S']
		}
	});











	//disableButtonSubmit 

	$('.submitYesNo').attr("disabled", true);
	$('.submitYesNo').on("click", function (e) {
		e.preventDefault();
	});

	// end disableButtonSubmit 


	// plata fractionara radio
	var labelsum = $(".labelsum"),
		sum = $(".sum"),
		radioTest = $(".radioTest"),
		deactivated = $(".deactivated")

	$('.allsum').on("click", function () {
		sum.attr('disabled', true);
		deactivated.removeAttr('disabled');
	})

	$('.disab').on("click", function () {
		labelsum.addClass('active');
		sum.removeAttr('disabled');
		sum.trigger("focus");
		radioTest.not(':checked').prop("checked", true);
		deactivated.attr('disabled', true);
	})

	$('.sum').on("keyup", function () {
		deactivated.removeAttr('disabled');
	})
	//end plata fractionara radio







	$("select")
		.not(".disabled")
		.trigger("select")
	var leftnav = $(".page-topbar").height();
	var leftnavHeight = window.innerHeight - leftnav;
	var righttnav = $("#slide-out-right").height();



	// materialize jquery








	$('ul.tabs').tabs();


	// checkbox all check
	$(".selectall").on("change", function () {
		$("div input").prop("checked", this.checked);
	});
	// end checkbox all check





	var todoOverlay = $(".todo-overlay");
	var bodyOverlay = $("body");
	var todoComposeSidebar = $(".todo-compose-sidebar");

	$(".todo-sidebar-trigger").on("click", function () {
		todoOverlay.addClass("show");

		todoComposeSidebar.addClass("show");
		bodyOverlay.addClass("o-hidden");

	})
	$(".close-icon, .todo-overlay ").on("click", function () {
		todoOverlay.removeClass("show");
		todoComposeSidebar.removeClass("show");
		bodyOverlay.removeClass("o-hidden");

	});




	$(".modal").modal({
		onOpenEnd: function (el) {
			$(el)
				.find(".tabs")
				.tabs({
					// swipeable: true
				});
		}
	});
	// $(".modal").modal();
    $('.tooltipped').tooltip();
	
	$('.collapsible').collapsible();
	  
	$('.collapsible.expandable').collapsible(
		{
			accordion: false
		}
	);

	$(".dropdown-trigger").dropdown({
		coverTrigger: false,
		constrainWidth: false,
		alignment: "right"
	});

	$(".scrollspy").scrollSpy({
		scrollOffset: 64
	});


	$("select").formSelect({
		dropdownOptions: {
			coverTrigger: false,
			// constrainWidth: false,
			alignment: "right"
		}
		// classes: 'lang-select'
	});



	$("select.lang-select").formSelect({
		dropdownOptions: {
			coverTrigger: false,
			constrainWidth: false,
			alignment: "right"
		},
		classes: "lang-select"
	});
	$("select.city-select").formSelect({
		dropdownOptions: {
			coverTrigger: false,
			constrainWidth: false,
			alignment: "right"
		},
		classes: "city-select"
	});




	$("select.vezi-select").formSelect({
		dropdownOptions: {
			coverTrigger: false,
			constrainWidth: true,
			alignment: "left"
		},
		classes: "vezi-select"
	});
	$("select.apart-select").formSelect({
		dropdownOptions: {
			coverTrigger: true,
			// constrainWidth: true,
			alignment: "left"
		},
		classes: "apart-select"
	});



	$(".sidenav").sidenav({
		// edge: "left", // Choose the horizontal origin
		// closeOnClick: true,
		// draggable: true
	});
	$("textarea#textarea2").characterCounter();

	// weather jquery
	$(".weather-temperature").openWeather({
		key: "8f32b32854565ef2b64ec4d059abb5c4",
		lang: "ro",
		city: "Targu-Mures, RO",
		placeTarget: ".weather-place",
		iconTarget: ".weather-icon",
		customIcons: "/images/weather/",
		success(data) {
			// show weather
			$(".weather-wrapper").show();
			console.log(data);
		},
		error(data) {
			console.log(data.error);
			$(".weather-wrapper").remove();
		}
	});

	// Fullscreen
	function toggleFullScreen() {
		if (
			(document.fullScreenElement && document.fullScreenElement !== null) ||
			(!document.mozFullScreen && !document.webkitIsFullScreen)
		) {
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	$(".toggle-fullscreen").on("click", function () {
		toggleFullScreen();
	});

	// $('.sidenav').sidenav();


































































































































































































	// $('#listaPlata').toggleClass('nav-collapsed');

	// $( "#listaPlata").on("click", function() {
	// 	$("#id_pentru_aside").toggleClass('nav-collapsed');
	//   });

	// Menu: Default menu collapse check
	var sidenavMain = $(".sidenav-main"),
		navCollapsible = $(".navbar .nav-collapsible")

	defaultMenuCollapse();

	function defaultMenuCollapse() {
		if ($("body, #residential, #financiar, #all_orders, .order-collapse").hasClass("menu-collapse") && $(window).width() > 993) {
			//  Toggle navigation expan and collapse
			sidenavMain.removeClass("nav-lock");
			$(".nav-collapsible .navbar-toggler i").text("radio_button_unchecked");
			navCollapsible.removeClass("sideNav-lock");
			toogleMenuCollapse();
			navigationCollapse();
		}
	}
	// Menu: Function for toggle class for menu collapse
	function toogleMenuCollapse() {
		if (sidenavMain.hasClass("nav-expanded") && !sidenavMain.hasClass("nav-lock")) {
			sidenavMain.toggleClass("nav-expanded");
			$("#main").toggleClass("main-full");
			$(".page-footer").toggleClass("main-full");
		} else {
			$("#main").addClass("main-full");
			$(".page-footer").addClass("main-full");
		}
	}
	// Menu: Collapse navigation
	function navigationCollapse() {
		if (!$(".sidenav-main.nav-collapsible").hasClass("nav-lock")) {
			var openLength = $(".collapsible .open").children().length;
			$(".sidenav-main.nav-collapsible, .navbar .nav-collapsible")
				.addClass("nav-collapsed")
				.removeClass("nav-expanded");
			$("#slide-out > li.open > a")
				.parent()
				.addClass("close")
				.removeClass("open");
			setTimeout(function () {
				// Open only if collapsible have the children
				if (openLength > 1) {
					var collapseEl = $(".sidenav-main .collapsible");
					var collapseInstance = M.Collapsible.getInstance(collapseEl);
					collapseInstance.close($(".collapsible .close").index());
				}
			}, 100);
		}
	}



	$(function () {
		// "use strict";

		// if (!is_touch_device()) {
		// 	// Right side slide-out (Chat, settings & timeline)
		// 	if ($("#slide-out.leftside-navigation").length > 0) {
		// 		if (!$("#slide-out.leftside-navigation").hasClass("native-scroll")) {
		// 			var ps_leftside_nav = new PerfectScrollbar(".leftside-navigation", {
		// 				wheelSpeed: 2,
		// 				wheelPropagation: false,
		// 				minScrollbarLength: 20
		// 			});
		// 		}
		// 	}
		// }


		// pentru cauta perioada

  $('.dropdown-interval').dropdown({
    closeOnClick: false,
    autoTrigger: true,
    coverTrigger: false,
    alignment: "right"
  
  });
  $('.dropdown-interval + .dropdown-content').on('click', function (event) {
    event.stopPropagation();
  });
		//end pentru cauta perioada



		// Collapsible navigation menu
		$(".nav-collapsible .navbar-toggler").on("click", function () {
			// Toggle navigation expan and collapse on radio click
			if (
				$(".sidenav-main").hasClass("nav-expanded") &&
				!$(".sidenav-main").hasClass("nav-lock")
			) {
				$(".sidenav-main").toggleClass("nav-expanded");
				$("#main").toggleClass("main-full");
				$(".page-footer").toggleClass("main-full");
			} else {
				$("#main").addClass("main-full");
				$(".page-footer").addClass("main-full");
			}
			// Set navigation lock / unlock with radio icon
			if (
				$(this)
					.children()
					.text() === "radio_button_unchecked"

			) {
				$(this)
					.children()
					.text("radio_button_checked");
				$(".sidenav-main").addClass("nav-lock");
				$(".navbar .nav-collapsible").addClass("sideNav-lock");
			} else {
				$(this)
					.children()
					.text("radio_button_unchecked");
				$(".sidenav-main").removeClass("nav-lock");
				$(".navbar .nav-collapsible").removeClass("sideNav-lock");
			}
		});

		// Expand navigation on mouseenter event
		$(".sidenav-main.nav-collapsible, .navbar .brand-sidebar").on("mouseenter", () => {
			if (!$(".sidenav-main.nav-collapsible").hasClass("nav-lock")) {
				$(".sidenav-main.nav-collapsible, .navbar .nav-collapsible")
					.addClass("nav-expanded")
					.removeClass("nav-collapsed");
				$("#slide-out > li.close > a")
					.parent()
					.addClass("open")
					.removeClass("close");

				setTimeout(() => {
					// Open only if collapsible have the children
					if ($(".collapsible .open").children().length > 1) {
						$(".collapsible").collapsible(
							"open",
							$(".collapsible .open").index()
						);
					}
				}, 100);
			}
		});

		// Collapse navigation on mouseleave event
		$(".sidenav-main.nav-collapsible, .navbar .brand-sidebar").on("mouseleave", () => {
			if (!$(".sidenav-main.nav-collapsible").hasClass("nav-lock")) {
				const openLength = $(".collapsible .open").children().length;
				$(".sidenav-main.nav-collapsible, .navbar .nav-collapsible")
					.addClass("nav-collapsed")
					.removeClass("nav-expanded");
				$("#slide-out > li.open > a")
					.parent()
					.addClass("close")
					.removeClass("open");
				// tslint:disable-next-line:only-arrow-functions
				setTimeout(function () {
					// Open only if collapsible have the children
					if (openLength > 1) {
						$(".collapsible").collapsible(
							"close",
							$(".collapsible .close").index()
						);
					}
				}, 100);
			}
		});




		// Detect touch screen and enable scrollbar if necessary
		function is_touch_device() {
			try {
				document.createEvent("TouchEvent");
				return true;
			} catch (e) {
				return false;
			}
		}
		if (is_touch_device()) {
			$("#nav-mobile").css({
				overflow: "auto"
			});
		}



	});


























































































	var searchListLi = $(".search-list li"),
		searchList = $(".search-list"),
		searchSm = $(".search-sm"),
		searchBoxSm = $(".search-input-sm .search-box-sm"),
		searchListSm = $(".search-list-sm");

	$(function () {
		"use strict";

		// On search input focus, Add search focus class
		$(".header-search-input")
			.on('focus', function () {
				$(this)
					.parent("div")
					.addClass("header-search-wrapper-focus");
			})
			.on('blur', function () {
				$(this)
					.parent("div")
					.removeClass("header-search-wrapper-focus");
			});

		//Search box form small screen
		$(".search-button").on('click', function (e) {
			if (searchSm.is(":hidden")) {
				searchSm.show();
				searchBoxSm.focus();
			} else {
				searchSm.hide();
				searchBoxSm.val("");
			}
		});
		// search input get focus
		$('.search-input-sm').on('click', function () {
			searchBoxSm.focus();
		});

		$(".search-sm-close").on('click', function (e) {
			searchSm.hide();
			searchBoxSm.val("");
		});
	});

}); // end turbo load


